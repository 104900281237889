/* 3.2 wpo-partners-section */
.wpo-partners-section {
    padding: 50px 0;
    position: relative;
    background: $white;

    h2 {
        display: none;
    }

    .partners-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: $dark-gray;
            opacity: .9;
        }

    }

    .grid {

        &:hover {

            img {
                filter: grayscale(100%);
            }
        }
    }


    .grid img {
        width: auto;
        margin: 0 auto;
        transition: all .4s ease-in-out;
    }

    .owl-nav {
        button {
            display: none;
        }
    }

    &.wpo-partners-section-s2 {
        background: #02245B;
        opacity: 0.8;
    }
}

/* 3.3 wpo-service-section */
.wpo-service-section {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(30deg, rgba(255, 94, 20, 0.7) 0%, rgba(255, 255, 255, 0.1) 20%);
    z-index: 1;

    .wpo-section-title {
        position: relative;
        z-index: 9;
    }

    &::before {
        background: url(../../images/service/service-bg.jpg);
        position: absolute;
        right: 0;
        top: 0;
        width: 91%;
        height: 89%;
        background-repeat: no-repeat;
        background-position: right;
        content: "";
        background-size: cover;
        z-index: -1;
        overflow: hidden;

        @media (max-width:1599px) {
            width: 95%;
        }

        @media (max-width:1499px) {
            width: 97%;
        }

        @media (max-width:1399px) {
            width: 99%;
        }

        @media (max-width:991px) {
            height: 100%;
            width: 100%;
            background-size: cover;
        }
    }

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background: rgba(255, 255, 255, 0.7);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 100%);
        z-index: -1;
    }

    .shape-1 {
        position: absolute;
        left: 20%;
        top: 10%;
        z-index: 1;

        @media (max-width:1599px) {
            left: 10%;
        }

        @media (max-width:1199px) {
            left: 0;
        }

        @media (max-width:991px) {
            display: none;
        }
    }


    .wpo-service-slider {
        margin-bottom: 120px;

        @media (max-width:991px) {
            margin-bottom: 0;
        }

        .grid {
            margin-top: 16px;

            .wpo-service-item {
                overflow: hidden;
                margin: 0 15px;

                .wpo-service-image {
                    overflow: hidden;

                    img {
                        width: 100%;
                        transition: all .3s ease-in-out;
                    }
                }

                .wpo-service-content {
                    padding-left: 30px;
                    padding-bottom: 50px;
                    background: $white;
                    position: relative;
                    padding-top: 135px;

                    &::before {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        content: "";
                        background: $theme-primary-color-s2;
                        width: 42px;
                        height: 42px;
                        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                        transition: all .3s ease-in-out;
                    }

                    .service-content-single {
                        width: 278px;
                        background: $theme-primary-color-s2;
                        padding: 25px;
                        text-align: left;
                        clip-path: polygon(100% 0, 100% 80%, 89% 100%, 0 100%, 0 0);
                        position: absolute;
                        left: 30px;
                        bottom: 140px;
                        transition: all .3s ease-in-out;

                        @media (max-width:1199px) {
                            width: 240px;
                            padding: 20px;
                        }

                        @media (max-width:767px) {
                            width: 300px;
                            padding: 20px;
                        }

                        h2 {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 29px;
                            color: $white;

                        }

                        span {
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 24px;
                            color: #C2C6CD;
                            transition: all .3s ease-in-out;
                        }
                    }

                }

                &:hover {
                    .wpo-service-image {
                        img {
                            transform: scale(1.2);
                        }
                    }

                    .wpo-service-content {
                        &::before {
                            background: $theme-primary-color;
                        }

                        .service-content-single {
                            background: $theme-primary-color;

                            span {
                                color: #EFEFEF;
                            }
                        }

                    }
                }
            }
        }


        .slick-arrow {

            &:before {
                position: absolute;
                top: 50%;
                @include translatingY();
                content: "\e629";
                font-family: themify;
                font-size: 18px;
                background: $white;
                width: 50px;
                height: 50px;
                line-height: 50px;
                padding: 0;
                margin: 0;
                color: $theme-primary-color;
                @include transition-time(0.3s);
                border: 0;

            }

            &:hover {
                &::before {
                    background: $theme-primary-color-s2;
                    color: $white;
                }
            }

            &.slick-prev {
                left: -7%;

                @media (max-width:1599px) {
                    left: -4%;
                }

                @media (max-width:1399px) {
                    left: -7%;
                }

            }

            &.slick-next {

                &::before {
                    content: "\e628";
                }

            }

        }


        .slick-dots {

            @media (min-width:992px) {
                display: none;
            }

            text-align: center;
            margin-top: 30px;
            bottom: unset;

            li {
                margin: 0;

                button {
                    width: 12px;
                    height: 12px;
                    background: $theme-primary-color-s2;
                    border-radius: 50%;
                    border: 0;

                    &::before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background: $theme-primary-color;
                    }
                }
            }


        }


    }

    .shape-2 {
        position: absolute;
        left: 0;
        bottom: 0;

        @media (max-width:991px) {
            display: none;
        }

        svg {
            circle {
                fill: #FF5E14;
            }
        }
    }
}

/* 3.4 wpo-cta-section */
.wpo-cta-section {
    position: relative;
    padding: 70px 0;
    z-index: 1;
    background: $theme-primary-color;

    @media (max-width:991px) {}

    .shape-2 {
        position: absolute;
        right: 30%;
        top: 38%;
        z-index: -1;

        @media (max-width:1199px) {
            display: none;
        }
    }


    .wpo-cta-text {
        width: 685px;

        @media (max-width:991px) {
            width: 100%;
        }

        h2 {
            font-size: 50px;
            font-weight: 700;
            line-height: 70px;
            text-align: left;
            color: $white;
            margin-bottom: 0;

            @media (max-width:1199px) {
                font-size: 42px;
                line-height: 60px;
            }

            @media (max-width:991px) {
                text-align: center;
            }

            @media (max-width:767px) {
                font-size: 35px;
                line-height: 50px;
            }

            @media (max-width:575px) {
                font-size: 33px;
                line-height: 50px;
            }

            @media (max-width:399px) {
                font-size: 27px;
                line-height: 40px;
            }

            .cta-text-bg {
                background: $white;
                color: $theme-primary-color-s2;
            }
        }
    }

    .wpo-cta-btn {
        text-align: right;

        @media (max-width:991px) {
            margin-top: 30px;
            text-align: center;
        }

        .theme-btn-s2 {
            background: $theme-primary-color-s2;
            color: $white;
            clip-path: polygon(100% 0, 100% 82%, 88% 100%, 0 100%, 0 0);
            transition: all .3 ease-in-out;

            i {
                transition: all .3 ease-in-out;

                &::before {
                    color: $white;
                    transition: all .3 ease-in-out;
                }
            }

            &:hover {
                color: $theme-primary-color;
                background: $white;

                i {
                    &::before {
                        color: $theme-primary-color;
                        background: $white;
                    }
                }
            }
        }
    }

    &.wpo-cta-section-s2 {
        margin-bottom: 120px;

        @media (max-width:991px) {
            margin-bottom: 90px;
        }

        @media (max-width:767px) {
            margin-bottom: 80px;
        }
    }
}

/* 3.5 wpo-about-section */
.wpo-about-section {
    position: relative;
    background: url(../../images/about/about-bg.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    z-index: 1;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: #F8F8F8;
        opacity: 0.88;
        z-index: -1;
    }

    .wpo-about-left {
        position: relative;
        margin-top: -42px;

        @media (max-width:991px) {
            margin-top: 70px;
            text-align: center;
        }

        .wpo-about-img-1 {
            display: inline-block;

            @media (max-width:1199px) {
                width: 25%;
            }

            @media (max-width:399px) {
                width: 24%;
            }

            img {
                @media (max-width:991px) {
                    width: 100%;
                }
            }

        }

        .wpo-about-img-2 {
            display: inline-block;
            margin: 0 20px;

            @media (max-width:1199px) {
                width: 35%;
            }

            img {
                @media (max-width:991px) {
                    width: 100%;
                }
            }

        }

        .wpo-about-img-3 {
            display: inline-block;

            @media (max-width:1199px) {
                width: 25%;
            }

            img {
                @media (max-width:991px) {
                    width: 100%;
                }
            }

        }

        .wpo-about-left-text {
            position: absolute;
            left: 36%;
            top: 62%;
            max-width: 400px;
            background: $theme-primary-color-s2;
            clip-path: polygon(100% 0, 100% 80%, 89% 100%, 0 100%, 0 0);
            padding: 28px 45px 17px;

            @media (max-width:1399px) {
                padding: 28px 40px 17px;
            }

            @media (max-width:1199px) {
                padding: 10px 10px 10px;
                top: 64%;
            }

            @media (max-width:991px) {
                padding: 15px 15px 15px;
                top: 66%;
                left: 39%;
            }

            @media (max-width:767px) {
                padding: 15px 15px 15px;
                top: 50%;
            }

            @media (max-width:575px) {
                max-width: 500px;
                top: 54%;
                left: 0;
            }

            @media (max-width:399px) {
                padding: 10px 10px 10px;
                top: 52%;
            }

            span {
                font-size: 15px;
                font-style: italic;
                font-weight: 500;
                line-height: 26px;
                text-align: left;
                color: $white;

                @media (max-width:1199px) {
                    font-size: 13px;
                    line-height: 20px;
                }

            }

            .text-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width:575px) {
                    justify-content: center;
                }

                @media (max-width:399px) {
                    display: none;
                }

                img {
                    @media (max-width:1199px) {
                        max-width: 90%;
                    }

                    @media (max-width:575px) {
                        max-width: 20%;
                    }
                }

                i {
                    @media (max-width:575px) {
                        margin-left: 30px;
                    }

                    &::before {
                        font-size: 79px;

                        @media (max-width:1199px) {
                            font-size: 70px;
                        }

                        @media (max-width:575px) {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        .shape-1 {
            position: absolute;
            left: 4%;
            top: 80%;

            @media (max-width:1199px) {
                top: 90%;
            }

            @media (max-width:991px) {
                top: 88%;
            }

            @media (max-width:767px) {
                display: none;
            }

        }
    }

    .wpo-about-right {
        margin-left: 100px;

        @media (max-width:1399px) {
            margin-left: 0;
        }

        @media (max-width:991px) {
            text-align: center;
        }

        .wpo-section-title {
            text-align: start;
            margin: 0;

            @media (max-width:991px) {
                text-align: center;
            }

            h2 {
                @media (max-width:1199px) {
                    font-size: 45px;
                    line-height: 55px;
                }

                @media (max-width:767px) {
                    font-size: 41px;
                }

                @media (max-width:575px) {
                    font-size: 25px;
                    line-height: 35px;
                }

                @media (max-width:399px) {
                    font-size: 23px;
                    line-height: 33px;
                }
            }

            p {
                margin-top: 15px;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                text-align: left;
                color: $text-light-color;
                margin-bottom: 15px;

                @media (max-width:991px) {
                    text-align: center;
                    padding: 0 30px;
                    margin-bottom: 15px;
                }

                @media (max-width:767px) {
                    padding: 0;
                    margin-bottom: 15px;
                }

                @media (max-width:575px) {
                    font-size: 15px;
                    line-height: 25px;
                }

                @media (max-width:399px) {
                    line-height: 23px;
                }
            }
        }

        .text {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            @media (max-width:991px) {
                padding: 0 30px;
            }

            @media (max-width:575px) {
                padding: 0;
            }

            .text-1 {
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                text-align: left;
                color: $text-color;
                padding-right: 62px;

                @media (max-width:991px) {
                    padding-right: 0;
                }

                @media (max-width:575px) {
                    font-size: 15px;
                    line-height: 20px;
                }

                &:first-child {
                    border-right: 2px solid $theme-primary-color;
                    padding-right: 20px;
                    margin-right: 42px;

                    @media (max-width:991px) {
                        padding-right: 30px;
                        margin-right: 32px;
                    }

                    @media (max-width:575px) {
                        padding-right: 20px;
                        margin-right: 22px;
                    }

                    @media (max-width:399px) {
                        padding-right: 20px;
                        margin-right: 22px;
                    }

                    @media (max-width:399px) {
                        padding-right: 5px;
                        margin-right: 7px;
                    }

                }
            }
        }

        .theme-btn {
            padding: 20px 43px;

            @media (max-width:767px) {
                padding: 12px 24px;
            }

            i {
                margin-left: 10px;
            }
        }

        &.wpo-about-right-s2 {
            margin-left: 0;
            margin-right: 100px;

            @media (max-width:1399px) {
                margin-right: 0;
            }

            @media (max-width:991px) {
                text-align: center;
            }
        }
    }

    .shape-2 {
        position: absolute;
        right: 4%;
        top: 15%;

        @media (max-width:1550px) {
            right: 0;
        }

        @media (max-width:1199px) {
            right: -6%;
        }

        @media (max-width:991px) {
            top: 9%;
        }

        @media (max-width:767px) {
            display: none;
        }

        img {
            @media (max-width:1199px) {
                max-width: 60%;
            }
        }
    }
}

/* 3.6 wpo-team-section */
.wpo-team-section {
    position: relative;
    background: $section-bg-color;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 2px;
        background: #F2F2F2;
        content: "";
    }

    .wpo-section-title h2 {

        @media (max-width:1199px) {
            font-size: 50px;
            line-height: 60px;
        }

        @media (max-width:767px) {
            font-size: 40px;
            line-height: 50px;
        }

        @media (max-width:575px) {
            font-size: 30px;
            line-height: 40px;
        }

        @media (max-width:399px) {
            font-size: 23px;
            line-height: 30px;
        }
    }

    .wpo-team-wrap {
        margin-top: 20px;

        .wpo-team-grid {
            position: relative;
            z-index: 1;
            overflow: hidden;
            border-radius: 270px;
            margin-bottom: 30px;

            @media (max-width:575px) {
                margin: 0 50px;
                margin-bottom: 30px;
            }

            @media (max-width:399px) {
                margin: 0;
                margin-bottom: 30px;
            }

            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                content: "";
                background: linear-gradient(180deg, rgba(2, 36, 91, 0) 42.93%, #02245B 100%);
                transition: all .3s ease-in-out;
                z-index: 11;
            }

            &:hover::before {
                background: linear-gradient(180deg, #FF5E1480 0%, #02245B 100%);
            }




            .images {
                position: relative;

                img {
                    width: 100%;
                }

                .team-grid-content {
                    .team-text {
                        position: absolute;
                        top: 48%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        z-index: 11;
                        text-align: center;
                        padding-bottom: 28px;
                        transition: all .3s ease-in-out;
                        opacity: 0;
                        visibility: hidden;

                        @media (max-width:1399px) {
                            top: 45%;
                        }

                        @media (max-width:1199px) {
                            top: 35%;
                        }

                        &::before {
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            transform: translateX(-50%);
                            content: "";
                            width: 42px;
                            height: 3px;
                            background: $white;
                        }

                        h2 {
                            font-size: 23px;
                            font-weight: 700;
                            line-height: 30px;

                            @media (max-width:1199px) {
                                font-size: 20px;
                                line-height: 25px;
                            }

                            @media (max-width:991px) {
                                line-height: 30px;
                                font-size: 23px;
                            }

                            a {
                                color: $white;
                            }
                        }

                        span {
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 24px;
                            margin-bottom: 0;
                            color: #E0DCDC;
                        }
                    }

                    .team-social-media {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 50%;
                        top: 85%;
                        transform: translateX(-50%);
                        z-index: 11;
                        text-align: center;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .3s ease-in-out;

                        li {
                            list-style: none;
                            display: inline-block;
                            margin: 0 7px;

                            a {
                                color: $white;
                                border-radius: 50%;
                                border: 2px solid $white;
                                padding: 16px 16px 10px;
                                transition: all .3s ease-in-out;
                                background: transparent;

                                i {
                                    padding-top: 5px;

                                    &::before {
                                        line-height: 16px;
                                        font-size: 16px;
                                    }
                                }

                                &:hover,
                                &.active {
                                    border: 2px solid transparent;
                                    background: $theme-primary-color;
                                }
                            }

                            &:nth-child(2) {
                                bottom: -20px;
                                position: relative;
                            }
                        }
                    }
                }
            }



            &:hover {

                .images {

                    .team-grid-content {
                        .team-text {
                            opacity: 1;
                            visibility: visible;
                        }

                        .team-social-media {
                            opacity: 1;
                            visibility: visible;
                            top: 80%;




                            @media (max-width:1199px) {
                                top: 70%;
                            }

                            @media (max-width:991px) {
                                top: 75%;
                            }
                        }
                    }

                }
            }

        }
    }

    .shape-1 {
        position: absolute;
        top: 10%;
        left: 0;

        @media (max-width:991px) {
            display: none;
        }
    }

    .boll-shape {
        li {
            background: #FF5E14;
            opacity: 0.08;
            height: 89px;
            width: 89px;
            border-radius: 100px;
            position: absolute;
            left: 1%;
            top: 36%;
            animation: pulse 2s ease-in infinite;
            list-style: none;

            @media (max-width:767px) {
                display: none;
            }

            &:nth-child(2) {
                height: 30px;
                width: 30px;
                left: 15%;
                top: 82%;
                animation: pulse 5s ease-in infinite;
            }

            &:nth-child(3) {
                height: 42px;
                width: 42px;
                left: 25%;
                top: 20%;
                animation: pulse 3s ease-in infinite;
            }

            &:nth-child(4) {
                height: 30px;
                width: 30px;
                left: 49%;
                top: 36%;
                animation: pulse 5s ease-in infinite;
            }

            &:nth-child(5) {
                height: 30px;
                width: 30px;
                left: 70%;
                top: 90%;
                animation: pulse 5s ease-in infinite;
            }

            &:nth-child(6) {
                height: 30px;
                width: 30px;
                left: 80%;
                top: 20%;
                animation: pulse 2s ease-in infinite;
            }

            &:nth-child(7) {
                height: 42px;
                width: 42px;
                left: 95%;
                top: 30%;
                animation: pulse 6s ease-in infinite;
            }

            &:last-child {
                height: 89px;
                width: 89px;
                left: 90%;
                top: 80%;
                animation: pulse 6s ease-in infinite;
            }
        }
    }
}

/* 3.7 wpo-project-section */
.wpo-project-section,
.wpo-project-section-s2 {
    background: $section-bg-color;
    position: relative;

    .wpo-section-title {
        text-align: start;
        margin-bottom: 65px;

        @media (max-width:991px) {
            text-align: center;
        }

        h2 {

            @media (max-width:1199px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media (max-width:575px) {
                font-size: 30px;
                line-height: 40px;
            }

            @media (max-width:399px) {
                font-size: 23px;
                line-height: 30px;
            }
        }
    }

    .wpo-project-wrap {

        .wpo-project-item {
            position: absolute;
            right: 15.9%;
            top: 17%;

            @media (max-width:1399px) {
                right: 3%;
            }

            @media (max-width:991px) {
                position: unset;
                margin-bottom: 40px;
            }

            .nav {
                justify-content: right;

                @media (max-width:991px) {
                    justify-content: center;
                }

                .nav-item {
                    margin-right: 10px;

                    @media (max-width:767px) {
                        margin-bottom: 10px;
                    }

                    @media (max-width:388px) {
                        margin-right: 0;
                    }

                    &:last-child {
                        margin-right: 0;

                        @media (max-width:767px) {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .nav-links {
                background: #f1f1f1;
                font-family: $heading-font;
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
                text-align: center;
                color: $text-color;
                width: 178px;
                border-radius: 0;
                border: 0;
                padding: 20px 0;
                border-bottom: 2px solid transparent;
            }

            .nav-links.active {
                color: $text-color-s2;
                border-bottom: 2px solid $text-color-s2;
            }
        }

    }

    .wpo-project-content {
        margin: 0 140px;

        @media (max-width:1599px) {
            margin: 0;
        }
    }

    .wpo-engeneer-slider {

        .grid {
            position: relative;
            overflow: hidden;
            z-index: 1;
            padding: 0 15px;

            @media (max-width:1199px) {
                padding: 0 5px;
            }

            .images {
                position: relative;
                clip-path: polygon(100% 0, 100% 88%, 88% 100%, 0 100%, 0 0);

                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(2, 36, 91, 0) 0%, #02245B 100%);
                    transition: all .3s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                }

                img {
                    width: 100%;
                    transition: all .3s ease-in-out;

                }
            }

            .text {
                position: absolute;
                width: 100%;
                top: 80%;
                left: 6%;
                transition: all .3s ease-in-out;
                opacity: 0;
                visibility: hidden;

                @media (max-width:1399px) {
                    top: 70%;
                }

                @media (max-width:1199px) {
                    left: 3%;
                }

                span {
                    font-family: $heading-font;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: $text-color-s2;

                }

                h2 {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 31px;
                    margin-bottom: 0;
                    color: $white;

                    @media (max-width:1199px) {
                        font-size: 21px;
                        line-height: 28px;
                    }

                    @media (max-width:767px) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .theme-btn {
                    padding: 15px 20px;
                    position: absolute;
                    right: 80px;
                    top: 0;

                    @media (max-width:1399px) {
                        right: 40px;
                    }

                    @media (max-width:1199px) {
                        right: 20px;
                        padding: 10px 15px;

                    }
                }
            }

            &:hover {
                .images {

                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }

                    img {
                        transform: scale(1.2)
                    }
                }

                .text {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .slick-arrow {
            z-index: 1;

            &:before {
                position: absolute;
                top: 50%;
                @include translatingY();
                content: "\e629";
                font-family: themify;
                font-size: 18px;
                background: $white;
                width: 50px;
                height: 50px;
                line-height: 50px;
                padding: 0;
                margin: 0;
                color: $theme-primary-color;
                @include transition-time(0.3s);
                border: 0;

            }

            &:hover {
                &::before {
                    background: $theme-primary-color-s2;
                    color: $white;
                }
            }

            &.slick-prev {
                left: -7%;

                @media (max-width:1599px) {
                    left: 0;
                }

                @media (max-width:1199px) {
                   
                }

            }

            &.slick-next {
                right: -4%;

                @media (max-width:1599px) {
                    right: 0;
                }

                &::before {
                    content: "\e628";
                }

            }

        }


        .slick-dots {

            @media (min-width:992px) {
                display: none;
            }

            text-align: center;
            margin-top: 30px;
            bottom: unset;

            li {
                margin: 0;

                button {
                    width: 12px;
                    height: 12px;
                    background: $theme-primary-color-s2;
                    border-radius: 50%;
                    border: 0;

                    &::before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background: $theme-primary-color;
                    }
                }
            }


        }

    }

}

/* 3.8 wpo-testimonial-section */
.wpo-testimonial-section {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(248, 248, 248, .1) 0%, #F7F7F7 100%);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: url(../../images/testimonial/bg-shape.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: -1;

    }

    .shape-1 {
        position: absolute;
        left: 10%;
        top: 24%;
        width: 120px;

        @media (max-width:991px) {
            top: 10%;
        }

        span {
            background: #FF5E14;
            opacity: 0.08;
            height: 114px;
            width: 114px;
            border-radius: 100px;
            position: absolute;
            left: -37.8%;
            top: -19%;
            z-index: -1;
            animation: pulse 5s ease-in infinite;

            @media (max-width:991px) {
                width: 80px;
                height: 80px;
                left: -4.8%;
                top: -16%;
            }

            @media (max-width:767px) {
                width: 50px;
                height: 50px;
            }
        }

        img {
            @media (max-width:991px) {
                margin-top: -150px;
                height: 100px;
            }

            @media (max-width:991px) {
                display: none;
            }
        }
    }

    .shape-2 {
        position: absolute;
        left: 74%;
        top: 20%;
        width: 170px;
        height: 100px;

        span {
            background: #FF5E14;
            opacity: 0.08;
            height: 114px;
            width: 114px;
            border-radius: 100px;
            position: absolute;
            left: -36.8%;
            top: -43%;
            z-index: -1;
            animation: pulse 10s ease-in infinite;

            @media (max-width:991px) {
                width: 80px;
                height: 80px;
                left: -4.8%;
                top: -16%;
            }

            @media (max-width:767px) {
                width: 50px;
                height: 50px;
            }
        }

        img {
            @media (max-width:991px) {
                margin-top: -140px;
                width: 100px;
                margin-left: 80px;
            }

            @media (max-width:991px) {
                display: none;
            }
        }
    }

    .wpo-section-title {
        h2 {
            @media (max-width:1199px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media (max-width:575px) {
                font-size: 30px;
                line-height: 40px;
            }

            @media (max-width:399px) {
                font-size: 23px;
                line-height: 30px;
            }
        }
    }

    .wpo-testimonial-wrap {
        position: relative;
        z-index: 1;
        background: url(../../images/testimonial/bg-images.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 20px;
        padding: 75px 0;
        clip-path: polygon(100% 0, 100% 80%, 94% 100%, 0 100%, 0 0);
        overflow: hidden;

        @media (max-width:1199px) {
            padding: 35px 0;
        }

        @media (max-width:991px) {
            clip-path: polygon(100% 0, 100% 80%, 90% 100%, 0 100%, 0 0);
        }

        @media (max-width:767px) {
            clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: rgb(255, 94, 20, 0.93);
            z-index: -1;
        }


        .wpo-testimonial-laft {
            margin-left: 100px;

            @media (max-width:1199px) {
                margin-left: 20px;
            }

            @media (max-width:575px) {
                margin-left: 0;
            }

            ul {
                li {
                    list-style: none;
                    margin: 0 auto;

                    img {
                        border-radius: 100%;
                        border: 5px solid $white;
                        width: 100%;

                        @media (max-width:1199px) {
                            width: 90%;
                        }

                        @media (max-width:991px) {
                            width: 250px;
                            margin: 0 auto;
                        }

                        @media (max-width:575px) {
                            width: 150px;
                        }
                    }
                }
            }
        }

        .wpo-testimonial-right {
            margin-left: 80px;
            margin-right: 90px;
            margin-top: -3px;

            @media (max-width:1199px) {
                margin-left: 30px;
                margin-right: 30px;
            }

            @media (max-width:991px) {
                margin-left: 50px;
                margin-right: 50px;
                margin-top: 30px;
            }

            @media (max-width:575px) {
                margin-left: 15px;
                margin-right: 15px;
            }

            .wpo-testimonial-content {
                text-align: left;
                position: relative;
                z-index: 1;

                .shape {
                    position: absolute;
                    left: 30%;
                    top: -13%;

                    img {
                        @media (max-width:1199px) {
                            width: 90%;
                        }
                    }

                }

                p {
                    position: relative;
                    font-family: $heading-font;
                    font-size: 21px;
                    font-weight: 700;
                    line-height: 35px;
                    margin-bottom: 0;
                    color: $white;
                    padding-bottom: 22px;

                    @media (max-width:1199px) {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    @media (max-width:575px) {
                        font-size: 15px;
                        line-height: 25px;
                    }

                    &::after {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 42px;
                        content: '';
                        height: 3px;
                        background: $white;
                    }
                }

                h2 {
                    font-size: 27px;
                    font-weight: 700;
                    line-height: 35px;
                    color: $white;
                    margin-top: 25px;

                    @media (max-width:1199px) {
                        font-size: 25px;
                        line-height: 30px;
                    }

                    @media (max-width:575px) {
                        font-size: 20px;
                        line-height: 25px;
                    }
                }

                span {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #FCF6F6;
                }
            }
        }

        .slick-arrow {
            background: $white;
            width: 50px;
            height: 50px;
            line-height: 65px;
            color: $theme-primary-color-s2;
            padding: 0;
            font-size: 0;
            margin: 0;
            -webkit-transition: all .3s;
            transition: all .3s;
            z-index: 1;
            top: auto;

            &:hover {
                background: $theme-primary-color-s2;
            }

            &.slick-next {
                position: absolute;
                bottom: -20px;
                right: 0;

                @media(max-width:991px) {
                    bottom: -22px;
                    right: 10%;
                }

                &:before {
                    font-family: 'themify';
                    content: "\e628";
                    font-size: 15px;
                    color: $theme-primary-color;
                }
            }

            &.slick-prev {
                position: absolute;
                bottom: -20px;
                left: auto;
                right: 70px;

                @media(max-width:991px) {
                    bottom: -22px;
                    right: 20%;
                }

                &:before {
                    font-family: 'themify';
                    content: "\e629";
                    font-size: 15px;
                    color: $theme-primary-color;
                }
            }

            @media(max-width:767px) {
                display: none !important;
            }
        }


        .slick-dots {
            text-align: center;
            bottom: -30px;
            display: none !important;

            @media(max-width:767px) {
                display: block !important;
            }

            li {
                width: unset;
                height: unset;

                button {
                    width: 15px;
                    background: $section-bg-color;
                    margin-right: 5px;
                    height: 5px;
                    padding: 0;

                    &::before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background: $theme-primary-color;
                    }
                }
            }

        }
    }

}

/* 3.9 wpo-blog-section */
.wpo-blog-section {
    position: relative;

    .shape {
        position: absolute;
        left: 5%;
        top: 15%;

        @media (max-width:1739px) {
            left: 0;
        }

        @media (max-width:1370px) {
            display: none;
        }
    }

    .wpo-section-title {
        text-align: start;
    }

    .wpo-section-title {

        @media (max-width:991px) {
            text-align: center;
        }

        h2 {

            @media (max-width:1199px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media (max-width:575px) {
                font-size: 30px;
                line-height: 40px;
            }

            @media (max-width:399px) {
                font-size: 23px;
                line-height: 30px;
            }
        }
    }

    .wpo-blog-wrap {

        .m-x {
            padding: 0 15px;
        }

        .grid {
            position: relative;
            overflow: hidden;
            background: #F8F8F8;
            padding: 60px 40px;
            transition: all .3s ease-in-out;

            @media (max-width:1399px) {
                padding: 30px 20px;
            }

            @media (max-width:991px) {
                padding: 50px 20px;
            }

            .images {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -111;
                opacity: 0;
                visibility: hidden;
                transition: all .3s ease-in-out;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    content: '';
                    z-index: 1;
                    background: #02245B;
                    opacity: 0.85;
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                text-align: left;

                span {
                    font-family: $heading-font;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: 0.06em;
                    color: $text-color-s2;
                    text-transform: uppercase;
                }

                h2 {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 40px;
                    color: $heading-color;
                    transition: all .3s ease-in-out;
                    margin-top: 22px;

                    @media (max-width:991px) {
                        line-height: 35px;
                    }

                    @media (max-width:399px) {
                        line-height: 25px;
                        font-size: 20px;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 28px;
                    color: $text-light-color;
                    margin-bottom: 23px;
                    transition: all .3s ease-in-out;
                }

                .theme-btn-s2 {
                    color: $white;
                    background: $theme-primary-color-s2;
                    clip-path: polygon(100% 0, 100% 85%, 85% 100%, 0 100%, 0 0);
                    transition: all .3s ease-in-out;

                    i {
                        &::before {
                            color: $white;
                            transition: all .3s ease-in-out;
                        }
                    }
                }
            }

            &:hover {
                background: transparent;

                .images {
                    opacity: 1;
                    visibility: visible;
                }

                .text {

                    h2 {
                        color: $white;
                    }

                    p {
                        color: $light;
                    }

                    .theme-btn-s2 {
                        background: $theme-primary-color;
                    }
                }
            }
        }

        .slick-list {
            margin: 0 -15px;
        }

        .slick-arrow {
            position: absolute;
            top: -25%;
            @include translatingY();

            &:before {
                position: absolute;
                top: 50%;
                @include translatingY();
                content: "\e629";
                font-family: themify;
                font-size: 18px;
                background: #F8F8F8;
                width: 50px;
                height: 50px;
                line-height: 50px;
                padding: 0;
                margin: 0;
                color: $theme-primary-color;
                @include transition-time(0.3s);
                border: 0;

            }

            &:hover {
                &::before {
                    background: $theme-primary-color-s2;
                    color: $white;
                }
            }

            &.slick-prev {
                right: 8%;
                left: auto;


            }

            &.slick-next {
                right: 3%;

                &::before {
                    content: "\e628";
                }

            }

        }

        .slick-dots {

            @media (min-width:992px) {
                display: none;
            }

            text-align: center;
            margin-top: 30px;
            bottom: unset;

            li {
                margin: 0;

                button {
                    width: 12px;
                    height: 12px;
                    background: $theme-primary-color-s2;
                    border-radius: 50%;
                    border: 0;

                    &::before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background: $theme-primary-color;
                    }
                }
            }


        }
    }



}